import applyButton from "@/lib/data-service/haolv-default/consumer_menu_applyButton"
import approveList from '@/lib/data-service/haolv-default/consumer_apply_approveList'
import consumer_apply_workflowApproveList from '@/lib/data-service/haolv-default/consumer_apply_workflowApproveList';
import moment from "moment";
export default {
    name: "HaveApprove",
    data() {
        return {
            type: '',
            tableLoad: false,
            options: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: '1',
                    label: '出差申请'
                },
                {
                    value: '2',
                    label: '超标申请'
                },
                {
                    value: '7',
                    label: '预订申请'
                },
                {
                  value: '4',
                  label: "改签申请",
                },
                {
                  value: '5',
                  label: "退订申请",
                },
            ],
            jurisdiction: false,   // 按钮权限范围
            tableData: []
        }

    },
    props: {
        formData: {
            type: Object
        },
        approve: {
            type: String, // have: 已审批， noHave： 未审批， invalid: 已失效
        }
    },
    methods: {
        getTimeDay(startDate,endDate){
            if (startDate != null && endDate != null) {
                return moment(endDate).diff(moment(startDate), 'days') + 1;
                // return (new Date(endDate)-new Date(startDate))/(1000*60*60*24)
            } else {
                return '--';
            }
        },
        goInfo(val, type) {
            // approveType 审批类型：1.出差申请，2报销申请，3.超标申请，4.改签，5.退票
            const approveType = Number(val.approveType);
            // appType: 1.我的申请 2待我审批
            if (approveType === 1 && true && val.approveType == 1 && val.status == 3 && val.appPoint == true) {
                // 新版审批流 + 待我审批 + 预订审批 + 审批驳回 + 回到起点
                this.$router.replace({
                  name: 'admin-add-evection',
                  query: {
                    type: 'exceed',
                    appType: 2,
                    applyNo: val.applyNo,
                    approveStatus: this.$route.name == 'admin-my-apply-manage-no-approve' ? 1 : this.$route.name == 'admin-my-apply-manage-have-approve' ? 2 : this.$route.name == 'admin-my-apply-manage-invalid-approve' ? 3 : 1
                  },
                });
                return
            }
            if (approveType === 2) {
                if (this.approve === 'noHave') {
                    this.$router.push({
                        name: "admin-my-apply-manage-apply-approve-exceed",
                        query: {
                            applyId: val.id,
                            applyNo: val.applyNo,
                            appType: 2,
                            approveStatus: val.approveStatus || val.status,
                            listApproveStatus: this.formData.approveStatus,
                            type: "approve",
                            lastMenu: 'adminNoApprove'
                        }
                    });
                    return
                }
                if (this.approve === 'have') {
                    this.$router.push({
                        name: "admin-my-apply-manage-apply-approve-exceed",
                        query: {
                            applyId: val.id,
                            applyNo: val.applyNo,
                            appType: 2,
                            approveStatus: val.approveStatus || val.status,
                            listApproveStatus: this.formData.approveStatus,
                            type: "approveDetail",
                            lastMenu: 'adminHaveApprove'
                        }
                    });
                    return
                }
                this.$router.push({
                    name: "admin-my-apply-manage-apply-approve-exceed",
                    query: {
                        applyId: val.id,
                        applyNo: val.applyNo,
                        appType: 2,
                        approveStatus: val.approveStatus || val.status,
                        listApproveStatus: this.formData.approveStatus,
                        type: "invalidApproveDetail",
                        lastMenu: 'adminInvalidApprove'
                    }
                });
                return
            }
            if(approveType === 4 || approveType === 5) {
                this.$router.push({
                    name: 'admin-my-apply-manage-noApprove-detail',
                    query: {
                        approveType,
                        type,
                        id: val.id,
                        applyNo: val.applyNo,
                        appType: 2,
                        approveStatus: val.approveStatus || val.status,
                        listApproveStatus: this.formData.approveStatus,
                    },
                })
            } else {
                if (this.approve === 'noHave') {
                    this.$router.push({
                        name: 'admin-my-apply-manage-noApprove-detail',
                        query: {
                            applyId: val.id,
                            applyNo: val.applyNo,
                            appType: 2,
                            approveStatus: val.approveStatus || val.status,
                            listApproveStatus: this.formData.approveStatus,
                            type,
                            approveType
                        }
                    })
                } else if (this.approve === 'invalid') {
                    this.$router.push({
                        name: 'admin-my-apply-manage-invalidApproval-detail',
                        query: {
                            applyId: val.id,
                            applyNo: val.applyNo,
                            appType: 2,
                            approveStatus: val.approveStatus || val.status,
                            listApproveStatus: this.formData.approveStatus,
                            type: 'havaReview',
                            approveType
                        }
                    })
                } else {
                    this.$router.push({
                        name: 'admin-my-apply-manage-haveApprove-detail',
                        query: {
                            applyId: val.id,
                            applyNo: val.applyNo,
                            appType: 2,
                            approveStatus: val.approveStatus || val.status,
                            listApproveStatus: this.formData.approveStatus,
                            type: 'havaReview',
                            approveType
                        }
                    })
                }
            }
        },
        edit() {
            this.$router.push({ name: 'admin-my-apply-manage-approve-info' })
        },
        expense() {
            this.$router.push({ name: 'admin-my-apply-manage-expense-apply' })
        },
        businessTravel() {
            // this.$router.push({ name: 'admin-my-apply-manage-apply-form' })
            this.$router.push({ name: 'admin-add-evection' })
        },
        handleCurrentChange(currPage) {
            this.formData.currentPage = currPage
            this.getList()
        },
        // 出差申请按钮状态
        async getApplyButton() {
            try {
                const res = await applyButton()
                this.jurisdiction = res.datas.jurisdiction
            } catch (error) {

            }
        },
        getList() {
            this.tableLoad = true;

            let myRequest = (myMethod) => {
                let data = Object.assign({}, this.formData);
                data.date && data.date.length > 0 ?
                    (data.startDateTime = data.date[0],
                        data.endDateTime = data.date[1]) : '';
                delete data.totalPage;
                myMethod(data)
                    .then((res) => {
                        let list = res.datas.list;
                        list.forEach((item) => {
                            if (item.recordList && item.recordList.length) {
                                const record = item.recordList[0];
                                item.lastNodeApprover = record.approverUserName;

                                item.lastNodeTime = moment(record.gmtCreate).format(
                                    "MM-DD hh:mm"
                                );

                                switch (record.approveStatus) {
                                    case 0:
                                        item.lastNodeStatus = "未开始";
                                        break;
                                    case 1:
                                        item.lastNodeStatus = "待审批";
                                        break;
                                    case 2:
                                        item.lastNodeStatus = "已审批通过";
                                        break;
                                    case 3:
                                        item.lastNodeStatus = "已审批拒绝";
                                        break;
                                    case 4:
                                        item.lastNodeStatus = "已失效";
                                        break;
                                }
                            } else {
                                item.lastNodeApprover = `发起人：${item.applyName}`;
                                item.lastNodeStatus = "";
                                item.lastNodeTime = "";
                            }
                        });
                        this.tableLoad = false;
                        this.formData.totalPage = res.datas.totalCount;
                        this.tableData = list;
                    })
            };

            // 审批列表
            if (this.type === "approve") {
                console.log(this.$store.state.workflow);
                myRequest(this.$store.state.workflow === '1' ? consumer_apply_workflowApproveList : approveList);
                // 申请列表
            } else if (this.type === "apply") {
                myRequest(req_myApplyList_datas);
            }
        }
    },
    created() {

    },
    async activated() {
        if (this.$route.name === 'admin-my-apply-manage-no-approve' || this.$route.name === 'admin-my-apply-manage-have-approve' || this.$route.name == 'admin-my-apply-manage-invalid-approve') {
            this.type = 'approve';
        }
        this.getApplyButton();
        await this.$store.state.workflowDefer.promise;
        this.getList();
    },
    watch: {},
    computed: {}
};
